/* C-FASTBOOKING - MOBILE FIRST (~ TABLET) */
.c-fastbooking {
	position: fixed;
	right: 100vw;
	border-radius: @soft-corners;
	background: @white;
	padding: 15px;
	z-index:4;
	.common-transition;

	.c-back-bar {
		display: none;
		min-height: 30px;
		padding: 0px;

		a.smenu-back {
			color: var(--b-blue);
			line-height: 30px;
			float: left;
			font-size: 14px;
			width: auto;
			height: auto; 
		}
	}

	&__form {
		position: relative;
		margin-bottom: 15px;

		input.mod--active,
		.c-fieldset .mod--rooms .mod--select-arrow.mod--active,
		.mod--discount-box.mod--active {
			.common-focus;
		}

		.c-fieldset {
			flex-wrap: wrap;
			justify-content: center;
			align-items: flex-end;

			&__row {
				margin: 6px 0px;
			}

			.title-mobile {
				display: none;
				font-size: 20px;
				line-height: 24px;
				font-weight: 700;
				margin-bottom: 10px;
			}

			.mod--destination,
			.mod--calendar,
			.mod--rooms,
			.mod--discount {
				label {
					// display: none;
				}
			}
			.mod--destination {
				label {
					display: none;
				}
			}

			.mod--not-allowed {
				cursor: not-allowed;
			}

			.mod--disabled {
				opacity: 0.6;
				text-decoration: none;
				cursor: not-allowed;
				pointer-events: none !important;
			}

			.mod--calendar,
			.mod--rooms,
			.mod--discount {
				input {
					cursor: pointer;
				}
			}
			
			.mod--destination {
				.c-delete-dates {
					display: none;
					font-weight: 500;
					line-height: 20px;
					font-size: 12px;
					cursor: pointer;
					height: 21px;
					margin-top: 11px;
					.ellipsis;

					&:after {
						font-family: "barcelo-ui-kit";
						content: "\e909";
						width: 10px;
						font-size: 10px;
						height: 10px;
						display: inline-block;
					}

					&:focus {
						border-radius: 2px;
						box-shadow: 0 0 2px 2px #c5e8f3;
						border: solid 1px var(--b-blue);
						outline: 0;
					}
				}
				&.mod--active-delete { 
					display: flex;
					align-items: center;
					justify-content: flex-end;
				}
			}

			.mod--destination,
			.mod--calendar {
				.c-optional-dates {
					font-weight: 300;
				}

				.left-input {
					width: 100%;

					.checkin {
						.mix-background-sprite(38px, 11, 0px);
					}
				}

				.right-input {
					display: none;
					flex-flow: column;
					align-items: flex-end;

					.checkout {
						.mix-background-sprite(38px, 17, 0px);
					}

					.c-delete-dates {
						display: none;
						font-weight: 500;
						line-height: 20px;
						font-size: 12px;
						cursor: pointer;
						height: 21px;
						margin-top: 11px;
						.ellipsis;

						&:after {
							font-family: "barcelo-ui-kit";
							content: "\e909";
							width: 10px;
							font-size: 10px;
							height: 10px;
							display: inline-block;
						}
					}
				}

				&.mod--active-delete {
					.c-optional-dates {
						display: none;
					}

					.c-delete-dates {
						display: block;
					}

					.right-input {
						display: flex;
						flex-direction: row;
						position: relative;
					}

					.left-input {
						width: 50%;

						.checkin {
							.mix-background-sprite(38px, 19, 0px);
						}
					}
				}
			}

			.mod--calendar {
				&.mod--active-delete {
					padding-bottom: 30px;
					.c-optional-dates {
						display: none;
					}

					.c-delete-dates {
						display: block;
						position: absolute;
						right: 0;
						bottom: -32px;
					}

					.right-input {
						display: flex;
						flex-direction: row;
						position: relative;
					}
				}
			}

			.mod--rooms {
				.mod--select-arrow {
					margin-right: 0px;
					border-radius: 2px 0px 0px 2px;
					height: 40px;
					display: inline-block;
					background-color: @white;
					padding: 0px 0.5rem;
					font-weight: 500;
					display: flex;
					align-items: center;
					border-radius: @soft-corners;
					color: @grey-70;
					border: 1px solid @grey-45;
					width: 100%;
					padding-right: 10px;
					.mix-background-sprite(38px, 21, ~"calc(100% + 5px)");

					.svg-icon {
						margin: 0px 0.25rem;

						.cmp-icon {
							line-height: 1.9rem;
						}
					}
				}

				&--hb,
				&--ad,
				&--kd {
					vertical-align: top;
					margin-top: -2px;
				}

				.mod--select-arrow {
					.svg-icon {
						height: 43px;
						width: 38px;
						margin: 0px;

						&.sprite-icon-room {
							.mix-background-sprite(38px, 35, 0px);
						}

						&.sprite-icon-pax {
							.mix-background-sprite(38px, 33, 0px);
						}
					}
				}
			}

			.mod--discount,
			.mod--rooms {
				position: relative;

				input {
					border: none;
					position: absolute;
					opacity: 0;
					bottom: 0;
				}
			}

			.mod--discount {
				.mod--discount-box {
					margin-right: 0px;
					border-radius: 2px;
					height: 40px;
					display: flex;
					align-items: flex-start;
					justify-content: center;
					flex-flow: column;
					background-color: @grey-20;
					padding: 0px 0.5rem;
					border: 1px solid @grey-45;
					width: 100%;
					color: var(--b-blue);
					-webkit-appearance: none;
					-moz-appearance: none;
					-ms-appearance: none;
					-o-appearance: none;
					background: white;
					.mix-background-sprite(40px, 25, ~"calc(100% + 5px)");
					line-height: 20px;

					span {
						display: flex;
						align-items: center;
						font-size: 16px;
						color: var(--b-blue);
						text-decoration: underline;
						font-weight: 700;
						padding-right: 20px;
						width: 100%;
					}
				}

				.mod--discount-box.mod--active {
					background: white;
					.mix-background-sprite(40px, 23, ~"calc(100% + 5px)");
					.common-focus;

					span {
						text-decoration: none;
					}
				}

				.mod--discount-box.enable {
					background-color: #ddecd4;
					border: 1px solid #cbdfbf;

					span {
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						height: 18px;
						color: @grey-90;
						font-weight: 300;
						font-size: 14px;
						text-decoration: none;
					}

					p, .discount-applied {
						font-size: 14px;
						font-weight: 700;
						color: @b-green;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						padding-right: 20px;
						line-height: 16px;
					}
				}
			}

			.mod--btn {
				font-size: 15px;
				&:not(.mod--cta-color-dark-blue),
        		&:not(.mod--cta-primary) {
					.c-cta {
						// display: flex;
						// align-items: center;
						// justify-content: center;
						// .mixin-cta-bg-color(@b-red, #be3300, @white, @white);
						// padding: 0px;
						// height: 40px;
					}
				}
				&.mod--cta-primary {
					.c-cta {
						background: var(--b-dark-blue-gradient);
						color: @white;
						border: none;
						&:hover {
							border: none;
						}
						&:active {
							color: @white;
						}
					}
				}
			}

			.flight-btn {
				display: none;
			}
		}

		.c-popover {
			.c-rooms-count {
				float: right;
				text-align: right;
				line-height: 2em;
				padding: 7px 2px;
			}
		}
	}

	&__links {
		display: flex;
		justify-content: center;
		width: 100%;

		&-add-flight {
			width: ~"calc(100% - 30px)";
			position: absolute;
			top: 5px;
			right: 15px;
			transition: none;
			text-align: right;

			.c-cta {
				text-align: right;
				.ellipsis;
				width: 100%;
			}
		}
	}

	/* C-FASTBOOKING - MODIFIERS - MOBILE FIRST (~ TABLET) */
	/* MODAL MODE */
	&.mod--active {
		position: fixed;
		right: 0vw;
		width: 100vw;
		height: 100vh;
		background: white;
		border-radius: 0px;
		.common-transition;
		.z-overmodal;
		z-index: 10;
		top: 0px;
		overflow-y: scroll;

		.c-back-bar {
			display: block;
		}

		.c-fastbooking__form {
			.c-fieldset {
				.title-mobile {
					width: 55vw;
					display: block;
					margin-bottom: 5px;
					color: #384044;
				}
			}
		}

		.c-fastbooking__links {
			&-add-flight {
				top: 20px;
				width: auto;
				max-width: 55%;
				.c-cta {
					white-space: normal;
					.ellipsis-multiline(14px, 18px, 2);
				}
			}
		}

		.c-popover__content-block {
			padding: 15px;
		}
		// avoid mobile behavoir inaccesible date and rooms
		.c-popover.mod--active {
			.c-popover__content.mod--active:not(.c-fastbooking__submenu-search) {
				.c-popover__content-block:not(.c-popover__content-block__result-hotels) {
					padding-bottom: 100px;
				}
			}
		}
	}

	&.mod--discount-disabled {
		.c-fieldset {
			.mod--discount {
				display: none;
			}
		}
  	}
}  
 
/* C-FASTBOOKING - POLICIES - MOBILE FIRST (~ TABLET) */
.mod--lg-heroslider.inject-fastbooking-true,
.mod--full-heroslider.inject-fastbooking-true {
	.c-fastbooking {
		position: relative;
		right: 0px;
		margin-top: @ifb-mobile-position-full;
	}
}

.mod--md-heroslider.inject-fastbooking-true {
	.c-fastbooking {
		position: relative;
		right: 0px;
		margin-top: @ifb-mobile-position-md;
	}
}

.mod--sm-heroslider.inject-fastbooking-true {
	.c-fastbooking {
		position: relative;
		right: 0px;
		margin-top: @ifb-mobile-position-sm;
	}
}

.activeFastbooking {
	.inject-fastbooking-true {
		.c-fastbooking {
			height: 100vh;
			position: fixed;
			top: 300px;
			margin-top: 0px;
			width: ~"calc(100% - 30px)";
			right: 15px;
			// .z-modal;
			z-index: 9;
			animation: fastbookingToFull 0.5s ease-out forwards;
		}

		@keyframes fastbookingToFull {
			from {
				top: 300px;
				margin-top: @ifb-mobile-position-full;
				width: ~"calc(100% - 30px)";
				right: 15px;
			}

			to {
				top: 0px;
				margin-top: 0px;
				width: 100vw;
				right: 0px;
				border-radius: 0;
			}
		}
	}

	.header,
	.c-ribbon {
		display: none;
	}
}

.search-open-JS {
	position: absolute;
	bottom: 38px;
	z-index: 99;
	width: auto;
	max-width: @content-sizing;
	left: 50%;
	transform: translateX(-50%);
}

.heroslider.inject-fastbooking-true .c-fastbooking.mod--active {
    position: fixed;
    margin-top: 0;
}
.heroslider.inject-fastbooking-true .c-fastbooking {
	border-radius: 16px;
}

/*  C-FASTBOOKING - DESKTOP  */
@media (min-width: @desktop) {
	/*  COMPACT VIEW */
	.c-fastbooking {
		position: relative;
		right: 0px;
		padding: 2px 0px;
		background: transparent;
		.z-navs;

		&__form {
			flex-wrap: wrap;
			justify-content: center;
			align-items: center;
			margin-bottom: 0px;

			.c-fieldset {
				justify-content: space-between;
				align-items: center;
				margin: 0px -4px;
				padding: 0px;

				&__row,
				&__row-wrap {
					padding: 0px 4px;
				}

				.mod--destination,
				.mod--calendar,
				.mod--rooms,
				.mod--discount {
					label,
					label[for="destination"] {
						// display: none;
						position: absolute;
						top: -9999px;
						left: -9999px;
						display: block;
					}
				}

				.mod--destination {
					width: ~"calc(25% - 20px)";
					.c-delete-dates {
						margin: 0px;
						color: transparent;
						position: relative;
						width: 25px;

						&:after {
							font-family: "barcelo-ui-kit";
							content: "\e909";
							width: 17px;
							font-size: 17px;
							height: 17px;
							position: absolute;
							color: var(--b-blue);
							right: 1px;
							top: 0px;
						}
					}
					&.mod--active-delete {
						flex-flow: row-reverse;
						justify-content: flex-end;
						input {
							order: 3;
						}
					}
				}

				.mod--calendar {
					width: 25%;

					.left-input {
						width: 50%;

						.checkin {
							.mix-background-sprite(36px, 19, 0px);
						}
					}

					.right-input {
						display: flex;
						flex-flow: row;
						align-items: center;
						flex-direction: row;

						.checkout {
							.mix-background-sprite(36px, 17, 0px);
						}

						.c-delete-dates {
							margin: 0px;
							color: transparent;
							position: relative;
							width: 25px;
							bottom: initial;

							&:after {
								font-family: "barcelo-ui-kit";
								content: "\e909";
								width: 17px;
								font-size: 17px;
								height: 17px;
								position: absolute;
								color: var(--b-blue);
								right: 0px;
								top: 0px;
							}
						}
					}

					&.mod--active-delete {
						padding-bottom: 0;
						.left-input {
							width: ~"calc(50% - 15px)";

							.checkin {
								.mix-background-sprite(36px, 19, 0px);
							}
						}

						.right-input {
							width: ~"calc(50% + 15px)";
							padding-bottom: 0;

							// .c-delete-dates {
							// 	display: block;
							// 	position: absolute;
							// 	right: 0;
							// 	bottom: initial;
							// 	top: 3px;
							// }
						}
					}
				}

				.mod--rooms {
					width: ~"calc(17% - 14px)";
				}

				.mod--discount {
					width: ~"calc(17% - 14px)";

					.mod--discount-box {
						.mix-background-sprite(40px, 25, ~"calc(100% + 5px)");
					}
				}

				.mod--btn {
					width: 15%;

					.c-cta {
						.mixin-cta-border-color(@grey-90, @transparent, @white, @grey-90, @grey-90);
					}
				}

				.mod--booking {	
					.c-cta {
						border: none;
						&:hover {
							border: none;
						}
					}
				}

				.flight-btn {
					display: flex;
					width: 48px;
					height: 40px;

					&-wrapper {
						width: 40px;
						height: 40px;
						border-radius: @soft-corners;
						border: 1px solid @grey-50;
						display: flex;
						justify-content: center;
						align-items: center;

						.svg-icon {
							.mod--size-32;
						}
					}
				}
			}
		}

		&__links {
			display: none;
		}

		&.mod--discount-disabled {
			.c-fieldset {
				.mod--destination {
					width: 31.22%;
				}

				.mod--calendar {
					width: 29.12%;
				}

				.mod--rooms {
					width: 19.12%;
				}
			}
		}

		&:not(.mod--cristal-clear):not(.mod--cristal-dark) {
			.c-fastbooking__form {
				.c-fieldset {
					.mod--btn,
					.mod--booking {
						.c-cta,
						&.c-cta {
							// background: var(--b-dark-blue-gradient);
							background: linear-gradient(90deg, rgb(48, 162, 191) 0%, rgb(46, 126, 153) 30%, rgb(47, 90, 118) 100%);
							line-height: 34px;
							color: #fff;
							background-position: 100%;
							box-shadow:none;
							border: none;
							.common-transition;
							display: inline-block;
							z-index: 1;
							margin-bottom: 0;
							&::before {
								content: "";
								position: absolute;
								top: 0;
								left: 0;
								width: 100%;
								height: 100%;
								opacity: 0;
								transition: opacity 0.3s;
								z-index: -1;
								background: var(--b-dark-blue-gradient-hover);
							}
							&:hover::before {
								opacity: 1;
							}
							&:hover {
								border: none;
								box-shadow: none;
							}
							&:active {
								background: var(--b-dark-blue-gradient-active);
								&::before {
									opacity: 1;
								  	background: var(--b-dark-blue-gradient-active);
                				}
							}
							&:focus-visible {
								// box-shadow: @b-blue-gradient-focused;
								box-shadow: 0px 0px 2px 1px rgb(1, 149, 208);
								border: solid 1px var(--b-blue);
							}
						}
						//loading spining
						&.loading-dots {
							&.mod--cta-color-dark-blue .c-cta,
							&.mod--cta-color-dark-blue.c-cta,
							&.mod--cta-color-dark-blue .c-cta:hover,
							&.mod--cta-color-dark-blue.c-cta:hover,
              				&.mod--cta-primary .c-cta,
							&.mod--cta-primary.c-cta,
							&.mod--cta-primary .c-cta:hover,
							&.mod--cta-primary.c-cta:hover {
								color: transparent;
								.common-transition;
								&:after {
									.spin;
								}
							}
						}
					}
				}
			}
		}
	}

	/* C-FASTBOOKING - VIEWS - DESKTOP */
	/*  HOME VIEW */
	.inject-fastbooking-true,
	.mod--full-heroslider.inject-fastbooking-true,
	.mod--lg-heroslider.inject-fastbooking-true,
	.mod--md-heroslider.inject-fastbooking-true,
	.mod--sm-heroslider.inject-fastbooking-true {
		.c-fastbooking {
			position: relative;
			right: 0px;
			padding: 12px 17px 10px;
			margin: 0px;
		}

		.inject-breadcrumb {
			position: absolute;
			top: 100px;
			z-index: 99;
			max-width: @content-sizing;
			width: 100%;
			left: 50%;
			transform: translateX(-50%);

			li {
				span,
				a {
					color: @white;
				}
			}
		}

		.inject-fastbooking {
			position: absolute;
			bottom: @ifb-desktop-position;
			.z-advs;
			width: 100%;
			max-width: @content-sizing;
			left: 50%;
			transform: translateX(-50%);

			.generic-container.bg-grey-20:first-child {
				background-color: transparent;
			}
		}

		.fastbooking {
			.c-fastbooking {
				background: @white;

				&__form {
					align-items: flex-start;

					.c-fieldset {
						align-items: flex-end;

						&__row {
							margin: 0px;
						}

						input,
						select {
							height: 48px;
						}

						.c-incrementers input {
							height: 40px;
						}

						input[type="checkbox"] {
							min-height: 20px;
							height: auto;
						}

						label,
						label[for="destination"] {
							display: block;
							position: initial;
							font-size: 14px;
							margin-top: 3px;
							line-height: 21px;
						}

                        .mod--destination {
                            width: 25%;
                            justify-content: space-between;
                            flex-flow: inherit;
                            .c-delete-dates {
                                width: auto;
                                padding-right: 1rem;
                                color: var(--b-blue);

                                &:after {
                                    font-family: "barcelo-ui-kit";
                                    content: "\e909";
                                    width: 10px;
                                    font-size: 10px;
                                    height: 10px;
                                    display: inline-block;
                                }
                            }
                        }

						.mod--calendar {
							.left-input {
								width: 50%;

								.checkin {
									background-position: 0px -679px;
								}
							}

							.right-input {
								width: 50%;
								flex-flow: column-reverse;
								align-items: flex-end;
								flex-direction: row;

								.checkout {
									background-position: 0px -607px;
								}

								.c-delete-dates {
									margin-top: 0px;
									margin-bottom: 3px;
									padding-right: 1rem;
									text-indent: 0px;
									position: relative;
									width: auto;
									color: var(--b-blue);

									&:after {
										font-family: "barcelo-ui-kit";
										content: "\e909";
										width: 12px;
										font-size: 10px;
										height: 17px;
									}
								}
							}

							&.mod--active-delete {
								.left-input {
									width: 50%;
								}

								.right-input {
									width: 50%;
									.c-delete-dates {
										display: block;
										position: absolute;
										right: 0;
										bottom: initial;
										top: 3px;
									}
								}
							}
						}

						.mod--rooms {
							width: 17%;

							.mod--select-arrow {
								height: 48px;
								.mix-background-sprite(45px, 21, ~"calc(100% + 5px)");

								&:focus {
									box-shadow: 0px 0px 3px 0px fade(@b-blue, 80%);
									border: solid 1px var(--b-blue);
								}
							}
						}

						.mod--discount {
							.mod--discount-box {
								height: 48px;
								.mix-background-sprite(48px, 25, ~"calc(100% + 5px)");

								&.mod--active {
									.mix-background-sprite(48px, 23, ~"calc(100% + 5px)");
								}
							}
						}

						.mod--btn {
							height: 48px;

							.c-cta {
								.mixin-cta-bg-color(@b-red, #be3300, @white, @white);
								border: none;
								font-size: 16px;
								padding: 0px;
								min-height: 48px;
							}
						}

						.flight-btn {
							display: none;
						}
					}
				}

				&__links {
					display: inline-flex;
					justify-content: space-between;
					align-items: center;
					margin-top: 7px;

					&-add-flight {
						width: auto;
						max-width: 50%;
						position: relative;
						top: auto;
						right: auto;
						text-align: left;

						.c-cta {
							text-align: left;
						}
					}

					.c-cta {
						font-weight: 700;
					}
				}

				&.mod--discount-disabled {
					.c-fieldset {
						.mod--destination {
							width: 35%;
						}

						.mod--calendar {
							width: 29.12%;
						}

						.mod--rooms {
							width: 19.39%;
						}
					}
				}
			}

			/* FASTBOOKING CRISTAL MODE*/
			.c-fastbooking {
				&.mod--cristal-clear {
					background-color: rgba(255, 255, 255, 0.15);

					.c-fastbooking__links {
						.c-cta {
							border-radius: 12px;
							padding: 5px 9px;
							line-height: initial;
							height: initial;
							text-decoration: underline;
						}

						&-add-flight .c-cta {
							color: white;
						}

						&-custom .c-cta {
							background-color: fade(@green-calendar-access, 70%);

							&:hover {
								color: @b-green;

								&:before {
									color: @b-green;
								}
							}
						}
					}
				}

				&.mod--cristal-dark {
					background-color: rgba(0, 0, 0, 0.2);
					box-shadow: inset 0px 0px 5px 0px rgba(0, 0, 0, 0.1);

					.c-fastbooking__links {
						.c-cta {
							border-radius: 12px;
							padding: 5px 9px;
							color: white;
						}

						&-add-flight .c-cta {
							background-color: fade(@b-light-blue, 50%);
						}

						&-custom .c-cta {
							background-color: fade(@green-calendar-access, 70%);
							&:hover {
								&:before {
									color: white;
								}
							}
						}
					}
				}

				&.mod--cristal-clear,
				&.mod--cristal-dark {
					.c-fastbooking__form {
						.c-fieldset {
							.mod--booking,
							.mod--btn {
								.c-cta {
									display: inline-block;
									&::before {
										content: none;
									}
								}
							}
						}
					}
					.c-fastbooking__links {
						.c-cta {
							border-radius: 12px;
							padding: 5px 9px;
							line-height: initial;
							height: initial;
							text-decoration: underline;
						}
					}
					&.mod--cristal-cta-white{
						.mod--btn {
							.c-cta{
								.mixin-cta-border-color(rgb(242, 242, 242), #ffffff, #384044, rgb(242, 242, 242), rgb(242, 242, 242));
								color: #384044;
								border: 0;
								&:hover {
									border: 0;
								}
							}
						}
					}
					//secondary-on-image styles
					&.mod--cta-secondary-on-image-fb {
						.c-fastbooking__form {
							.mod--btn {
								.c-cta {
									font-size: 16px;
									font-weight: 600;
									line-height: 32px;
									height: 48px;
									padding: 8px 32px;
									border-radius: 25px;
									box-shadow: none;
									text-align: center;
									text-decoration: none;
									text-shadow: none;
									display: inline-block;
									z-index: 1;
									overflow: hidden;
									text-overflow: ellipsis;
									white-space: nowrap;
									.common-transition;
									background: @white;
									border-color: @white;
									color: @b-dark-blue;
									&:focus-visible {
										box-shadow: @b-blue-gradient-focused;
										outline: none;
									}
									&:hover {
										background: @grey-25;
										border-color: @grey-25;
										color: @b-dark-blue;
										box-shadow: none;
									}
									&:active {
										background: @b-dark-blue;
										border-color: @b-dark-blue;
										color: @white;
									}
								}
							}
						}
					}
					.c-fastbooking__form > .c-fieldset label {
						color: white;
					}

					.mod--destination input,
					.mod--calendar input,
					.mod--rooms input,
					.mod--discount input,
					.c-fastbooking__form .c-fieldset .mod--rooms .mod--select-arrow,
					.c-fastbooking__form .c-fieldset .mod--discount .mod--discount-box {
						// background-color: rgba(255, 255, 255, 0.2);
						// background-color: rgba(0, 0, 0, 0.6);
						background-color: rgba(0, 0, 0, .3);
						font-weight: 300;
						border: solid 1px white;
						.placeholder(white, 16px, normal, none, 1, 0px);
						color: white;
					}

					input {
						&::placeholder {
							color: white;
						}
					}
					
					.c-fastbooking__form input.mod--active,
					.c-fastbooking__form .c-fieldset .mod--rooms .mod--select-arrow.mod--active,
					.c-fastbooking__form .mod--discount-box.mod--active {
						border-color: white;
					}

					.c-fastbooking__form .c-fieldset .mod--discount .mod--discount-box span {
						color: white;
					}

					.mod--btn .c-cta {
						.mixin-cta-border-color(@white, rgba(255, 255, 255, 0.2), @grey-90, @white, @white);
					}

					.c-fieldset {
						.mod--calendar {
							.left-input {
								.checkin {
									.mix-background-sprite(38px, 39, 0px);
								}
							}

							.right-input {
								.checkout {
									.mix-background-sprite(38px, 37, 0px);
								}

								.c-delete-dates {
									color: #ffffff;

									&:after {
										color: #ffffff;
									}
								}
							}
                        }
                        
                        .mod--destination {
                            justify-content: space-between;
                            flex-flow: inherit;
                            .c-delete-dates {
                                width: auto;
                                padding-right: 1rem;
                                color: @white;

                                &:after {
                                    font-family: "barcelo-ui-kit";
                                    content: "\e909";
                                    width: 10px;
                                    font-size: 10px;
                                    height: 10px;
                                    display: inline-block;
                                    color: @white;
                                }
                            }

                            input {
                                
                                .c-delete-dates {
                                    color: @white;

                                    &:after {
                                        color: @white;
                                    }
                                }
                            }
                        }

						.mod--rooms {
							.mod--select-arrow {
								.mix-background-sprite(38px, 49, ~"calc(100% + 5px)");
							}

							.mod--select-arrow {
								.svg-icon {
									&.sprite-icon-room {
										.mix-background-sprite(38px, 47, 0px);
									}

									&.sprite-icon-pax {
										.mix-background-sprite(38px, 45, 0px);
									}
								}
							}
						}

						.mod--discount {
							.mod--discount-box {
								.mix-background-sprite(40px, 41, ~"calc(100% + 5px)");
							}

							.mod--discount-box.mod--active {
								.mix-background-sprite(40px, 43, ~"calc(100% + 5px)");
							}
						}
					}
				}
			}
		}

		
	}

	/* P&T */
	&.bpt-fastbooking.fastbooking {
		.c-fastbooking__links-add-flight {
			.c-cta {
				display: none;
			}
		}
	}
}

@import (once) "c-fastbooking__submenu.less";
