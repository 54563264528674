//	 __          __              _             _
//	 \ \        / /             (_)           | |
//	  \ \  /\  / /_ _ _ __ _ __  _ _ __   __ _| |
//	   \ \/  \/ / _` | '__| '_ \| | '_ \ / _` | |
//	    \  /\  / (_| | |  | | | | | | | | (_| |_|
//	     \/  \/ \__,_|_|  |_| |_|_|_| |_|\__, (_)
//	                                      __/ |
//	                                     |___/
//
//	   ANY CHANGES MADE HERE WILL TAKE EFFECT ON THE "NEW ROYAL HIDEAWAY (BRH)" FASTBOOKING COMPONENT

/* INPUTS */
form {
  font-size: 16px;
}

label {
  font-weight: 700;
}

input[type="text"],
input[type="tel"],
input[type="password"],
input[type="email"],
input[type="search"],
input[type="url"],
input[type="date"],
input[type="number"],
input[type="month"],
input[type="week"],
input[type="time"],
select {
  width: 100%;
  height: @input-height;
  border: 1px solid @grey-45;
  font-weight: 500;
  line-height: 24px;
  border-radius: @soft-corners;
  color: @grey-90;
  padding: 0px 0.5rem;
  .placeholder(@grey-70, 16px, normal, none, 1, 0px);
  &:focus {
    .common-focus;
  }
  &.mod--with-icon {
    padding: 0px 0px 0px 34px;
    &.searchdate {
      .mix-background-sprite(38px, 11, 0px);
    }
    &.checkin {
      .mix-background-sprite(38px, 19, 0px);
    }
    &.checkout {
      .mix-background-sprite(38px, 17, 0px);
    }
  }
  &.mod--big {
    height: 48px;
  }
  &.mod--select-arrow {
    .mix-background-sprite(45px, 21, ~"calc(100% + 5px)");
    padding-right: 13%;
  }
}

input[type="text"],
input[type="tel"],
input[type="password"],
input[type="email"],
input[type="search"],
input[type="url"],
input[type="date"],
input[type="number"],
input[type="month"],
input[type="week"],
input[type="time"] {
  &.mod--validate,
  &.mod--invalidate {
    padding-right: 2.5rem;
    background-repeat: no-repeat;
    background-size: 14px auto;
    background-position: ~"calc(100% - 15px)" center;
  }
  &.mod--validate {
    background: white;
    .mix-background-sprite(38px, 13, ~"calc(100% + 5px)");
  }
  &.mod--invalidate {
    background: white;
    .mix-background-sprite(38px, 15, ~"calc(100% + 5px)");
    border-color: @red-error;
    &:focus {
      border-radius: 2px;
      box-shadow: 0 0 2px 2px @b-red;
      border-color: @red-error;
      outline: none;
    }
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

textarea {
  width: 100%;
  height: auto;
  background: @white;
  border: 1px solid @grey-45;
  padding-left: 6px;
  font-weight: 500;
  line-height: 24px;
  border-radius: @soft-corners;
  color: @grey-90;
  resize: none;
}

.c-textarea {
  width: 100%;
  position: relative;
  textarea {
    padding-bottom: 24px;
  }
  &__counter {
    color: @grey-90;
    position: absolute;
    right: 20px;
    bottom: 16px;
    font-size: 12px;
    padding: 0px 7px;
    border-radius: 2px;
    opacity: 0.8;
    background: white;
    z-index: 1;
    font-weight: 700;
  }
}

select {
  .delete-input-style;
  border-radius: @soft-corners;
  .mix-background-sprite(40px, 21, ~"calc(100% + 5px)");
  padding-right: 13%;
  cursor: pointer;
  &::-ms-expand {
    display: none;
  }
}

select.c-select-placeholder {
  color: @grey-50;
  font-size: 16px;
  font-style: normal;
  opacity: 1;
}

select.c-select-placeholder:focus {
  color: inherit;
}

select.c-select-placeholder {
  option[value=""][disabled] {
    display: none;
  }
  option {
    color: @grey-90;
    cursor: pointer;
  }
}

.c-fieldset {
  display: flex;
  flex-wrap: wrap;
  margin: 0px -4px;
  padding: 4px 0px;
  label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    margin-bottom: 3px;
    &.required:after {
      content: "*";
    }
    &.mod--multiline {
      white-space: normal;
      display: inline-flex;
    }
  }
  span {
    &.required:after {
      content: "*";
    }
  }
  &__row,
  &__row-wrap {
    width: 100%;
    display: flex;
    padding: 0px 4px;
    /*modifiers*/
    .country-code {
      max-width: 18%;
      margin-right: 15px;
    }
    .policy-label {
      margin-left: 0.5rem;
      font-weight: 500;
    }
  }
  &__row-wrap {
    flex-wrap: nowrap;
    align-items: center;

    &-col {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding: 0px 4px;
      &:first-child {
        padding-left: 0px;
      }
      &:last-child {
        padding-right: 0px;
      }
    }
    &.mod--mobile-nowrap {
      flex-wrap: wrap;
      .c-fieldset__row-wrap-col {
        width: 100%;
        padding: 4px 0px;
      }
    }
  }
  &__row {
    flex-wrap: wrap;
    .c-fieldset__row-wrap {
      padding: 0px;
    }
  }
  &__row-date.flatpickr {
    .mix-background-sprite(35px, 10, 3px);
  }
}

.c-double-input {
  display: inline-flex;
  width: 100%;
  .left-input {
    width: 50%;
    float: left;
    input {
      margin-right: 0px;
      border-radius: @soft-corners 0px 0px @soft-corners;
    }
  }
  .right-input {
    width: 50%;
    margin-left: -1px;
    input {
      border-radius: 0px @soft-corners @soft-corners 0px;
    }
  }
}

.c-overslap-button {
  width: 100%;
  height: @input-height;
  position: relative;
  overflow: hidden;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  input[type="text"] {
    width: ~"calc(100% - 50px)";
  }
  input[type="button"] {
    height: 100%;
    position: absolute;
    right: 0px;
    padding: 0px 1.525rem 0px;
    top: 0px;
    box-shadow: -20px 0px 20px 0px white;
  }
}

input[type="checkbox"],
input[type="radio"],
input[type="checkbox"]:checked,
input[type="radio"]:checked {
  .delete-input-style;
  background: @white;
  border: 1px solid @grey-45;
  color: @grey-90;
  cursor: pointer;
  box-shadow: inset 0px 1px 4px 0px fade(@grey-90, 50%);
  vertical-align: text-top; // prevent the jumping on checked.
}

input[type="checkbox"] {
  width: 20px;
  height: 20px;
  border-radius: @soft-corners;
  margin: 2px;
  &:checked {
    width: 20px;
    height: 20px;
    border-radius: @soft-corners;
    background: var(--b-blue);
    box-shadow: 0px 0px 2px 2px @b-light-blue;
    &:after {
      font-family: "barcelo-ui-kit";
      content: "\e926";
      color: @white;
      font-size: 14px;
      font-weight: 300;
      padding: 2px;
      line-height: 19px;
    }
  }
  &.mod--orange {
    &:checked {
      background: @white;
      box-shadow: none;
      &:after {
        color: @b-red;
      }
    }
  }
  &.mod--small {
    width: 16px;
    height: 16px;
    min-width: 16px;
    &:after {
      vertical-align: top;
      font-size: 10px;
      line-height: 16px;
    }
  }
}

input[type="radio"] {
  width: 24px;
  height: 24px;
  border-radius: 24px;
  text-align: center;
  &:checked {
    background: var(--b-blue);
    &:before {
      font-family: "barcelo-ui-kit";
      content: "\e92d";
      color: white;
      font-size: 21px;
      line-height: 24px;
    }
  }
}

.c-incrementers {
  display: flex;
  justify-content: center;
  align-items: center;
  input[type="number"] {
    text-align: center;
    width: 64px;
    height: 40px;
    margin: 0px 8px;
  }
  &__btn {
    width: 24px;
    height: 24px;
    text-align: center;
    font-size: 19px;
    cursor: pointer;
    line-height: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--b-blue);
    border-radius: @circle;
    color: @white;
    overflow: hidden;
    box-shadow: 0px 2px 2px 0px fade(@black, 30%);
  }
  .mod--btn-disabled {
    cursor: default;
    opacity: 0.5;
  }
}

.formCheck {
  input:invalid,
  select:invalid,
  textarea:invalid {
    border: 1px solid @b-red !important;
  }
}

.c-input-error-message,
.c-input-empty-message {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: @red-error;
  margin-top: 0px;
  overflow: hidden;
  opacity: 0;
  max-height: 0px;
  width: 0px;
  transition: all 0.5s linear;
}

.mod--active-message-error {
  .c-input-error-message {
    opacity: 1;
    max-height: 40px;
    width: 100%;
    margin-top: 8px;
  }
}

.mod--active-message-empty {
  .c-input-empty-message {
    opacity: 1;
    max-height: 40px;
    width: 100%;
    margin-top: 8px;
  }
}

input[disabled],
select[disabled] {
  cursor: not-allowed;
}

.mod--rounded-form {
  .c-fieldset {
    input[type="text"],
    input[type="tel"],
    input[type="password"],
    input[type="email"],
    input[type="search"],
    input[type="url"],
    input[type="date"],
    input[type="number"],
    input[type="month"],
    input[type="week"],
    input[type="time"],
    select {
      border-radius: 8px;
    }
  }
}

/* GENERAL FORMS - DESKTOP  */
@media (min-width: @desktop) {
  .c-fieldset {
    margin: 15px -15px;
    &__row-wrap {
      padding: 0px 15px;
      &-col {
        padding: 0px 15px;
      }
      &.mod--mobile-nowrap {
        flex-wrap: nowrap;
        .c-fieldset__row-wrap-col {
          width: 100%;
          padding: 0px 15px;
          &:first-child {
            padding-left: 0px;
          }
          &:last-child {
            padding-right: 0px;
          }
        }
      }
    }
    &__row {
      padding: 0px 15px;
    }
  }
}
